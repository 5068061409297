<template>
    <div class="container notifications-container">
        <Breads :links="breadLinks"/>
        <PageHeader :title="$t('Notifications')"/>
        <div class="content">
            <Preloader
                v-if="preloader"
                :preloader="preloader"
            />
            <div class="item" v-for="item in notificationsList" v-if="!preloader">
                <div class="date">{{item.date}}</div>
                <div class="notifications" >
                    <div class="notification-item" v-for="notification in item.items">
                        <div class="item-container">
                            <div class="label-good" v-if="notification.status === 'success'">
                                <span class="material-icons label">check_circle</span>
                            </div>
                            <div class="label-bad" v-else-if="notification.status === 'fail'">
                                <span class="material-icons-outlined label">error</span>
                            </div>
                            <span>{{notification.message}}</span>
                        </div>
                        <div class="time-message">
                            {{notification.time}}
                        </div>
                        <a class="link" :title="$t('Download')" :href="notification.link" v-if="notification.link">
                            <span class="material-icons">download</span>
                        </a>
                    </div>
                </div>
            </div>
            <Pagination v-if="notificationsMeta.total && !preloader" class="pagination" :value="pageNumber" :total="notificationsMeta.total" :per-page="notificationsMeta.per_page" @input="changePage"></Pagination>
        </div>
    </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import Breads from "@/components/Breads";
import Pagination from "../../components/ui/tables/Pagination";
import Preloader from "../../components/ui/Preloader";

export default {
    name: "Notifications",
    components:{
        PageHeader,
        Breads,
        Pagination,
        Preloader
    },
    data(){
        return {
            breadLinks: [
                {
                    label: this.$t('Notifications'),
                    to: { name: 'Notifications' }
                }
            ],
            notificationsList: [],
            notificationsMeta: [],
            pageNumber: 1,
            preloader: true
        }
    },
    watch: {
        pageNumber(){
            this.getNotifications()
        }
    },
    methods:{
        async getNotifications(){
            this.preloader = true
            this.notificationsList = []
            let resp = await this.api.notifications.getNotifications({page: this.pageNumber})
            this.notificationsMeta = resp
            resp.data.forEach(notification => {
                notification.data.time = this.helper.getTime(notification.updated_at)
                let attached = false
                if (!this.notificationsList.find(el => el.date === this.helper.getDate(notification.created_at))){
                    attached = true
                    this.notificationsList.push({
                        date: this.helper.getDate(notification.created_at),
                        items: [notification.data]
                    })
                }
                if (!attached){
                    this.notificationsList.forEach(item => {
                        if (item.date === this.helper.getDate(notification.created_at)) {
                            item.items.push(
                                notification.data,
                            )
                        }
                    })
                }
            })
            this.preloader = false
        },
        changePage(p){
            this.pageNumber = p
            this.$router.push({ query: Object.assign({}, this.$route.query, {page: p }) }).catch(()=>{});
        },
        getOpenPage(){
            if (this.$route.query.page){
                this.pageNumber = +this.$route.query.page
            }
        }
    },
    created() {
        this.getOpenPage()
        this.getNotifications()
    }
}
</script>

<style scoped lang="scss">
.notifications-container{
    .content{
        min-height: 500px;
        background-color: white;
        padding: 40px 113px;
        @media (max-width: 768px) {
            padding: 20px;
        }
        .item{
            margin-top: 20px;
            position: relative;
            &:first-child{
                margin-top: 0;
            }
            .date{
                width: 100px;
                height: 31px;
                border-radius: 2px;
                background-color: $color_light;
                font-size: 14px;
                color: #141213;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .notifications{
                .notification-item{
                    padding: 20px 0;
                    border-bottom: 1px solid $color_light;
                    border-radius: 2px;
                    font-size: 18px;
                    line-height: 1.5;
                    color: #141213;
                    display: flex;
                    justify-content: space-between;
                    .item-container{
                        display: flex;
                        margin-right: 90px;
                    }
                    .link{
                        display: flex;
                        align-items: center;
                        .material-icons{
                            font-size: 20px;
                            color: $color_darkblue;
                        }
                    }
                    .label-good{
                        color: #70dc95;
                    }
                    .label-bad{
                        color: #ff496d;
                    }
                    .label{
                        font-size: 20px;
                        padding-right: 17px;
                    }
                    &:last-child{
                        border-bottom: none;
                    }
                }
            }
            .time-message{
                font-size: 14px;
                letter-spacing: 0.35px;
                color: #646e78;
                display: flex;
                align-items: center;
                position: absolute;
                right: 9%;
                padding-top: 4px;
            }
        }
    }
    .pagination{
        margin-top: 90px;
        @media (max-width: 768px) {
            margin-top: 40px;
        }
    }


    ::v-deep{
        .page-header{
            margin-top: 13px;
        }
        .pagination-row .pages a{
            @media (max-width: 576px) {
                width: 7vw;
            }
        }
    }
}
</style>

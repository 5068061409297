<template>
    <div v-if="total > perPage" class="pagination-row">
        <ButtonComponent v-if="pageNumber !== 1" class="pagination-row__button" @click="setFirstPage" size="medium" theme="bordered-base" :caption="$t('First')"/>
        <paginate
            v-model="pageNumber"
            :page-count="pages"
            :prev-text="prevHtml"
            :next-text="nextHtml"
            container-class="pagination-row"
            next-class="pages"
            prev-class="pages"
            page-class="pages"
            :hide-prev-next="true"
        />
        <ButtonComponent v-if="pageNumber !== pages" class="pagination-row__button pagination-row__button--blue" @click="setLastPage" size="medium" theme="primary" :caption="$t('Latter')"/>
    </div>
</template>

<script>
import ButtonComponent from "@/components/ui/ButtonComponent"

export default {
    name: "Pagination",
    components: { ButtonComponent },
    props: {
        value: {
            required: true
        },
        total: {
            required: true,
            type: Number
        },
        perPage: {
            required: false,
            default: 20,
            type: Number
        }
    },
    data() {
        return {
            pageNumber: this.value || 1,
            nextHtml: '<span aria-hidden="true">&raquo;</span>',
            prevHtml: '<span aria-hidden="true">&laquo;</span>'
        };
    },
    watch: {
        pageNumber() {
            this.$emit("input", this.pageNumber);
        }
    },
    computed: {
        pages() {
            return Math.ceil(this.total / this.perPage);
        }
    },

    methods: {
        setFirstPage() {
            this.pageNumber = 1;
        },
        setLastPage() {
            this.pageNumber = this.pages
        }
    }
};
</script>

<style lang="scss">
.pagination-row {
    display: flex;
    justify-content: center;

    &__button {
        &--blue {
            box-shadow: 0 4px 10px 0 rgba(53, 0, 212, 0.25);
        }


        &:first-child {
            margin-right: 10px;
        }
        &:last-child {
            margin-left: 10px;
        }
    }

    .pages {
        list-style-type: none;
        margin: 0 3px;
        transition: 0.35s all;
        border-radius: 2px;
        border: solid 1px #cad9f37a;

        a {
            width: 40px;
            height: 40px;
            font-family: $ff-primary;
            color: $color_gray;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
        }

        &.disabled {
            color: #c2c5cb;
            pointer-events: none;
        }

        &.active,
        &:hover {
            opacity: 1;
            border: solid 1px $color_lightblue;

            a {
                color: $color_black;
            }
        }
    }
}
</style>
